import {clsx} from '@local/utils'
import {useEffect, useState} from 'react'
import {Tooltip} from 'react-tooltip'
import {useDevice} from '@local/hooks'
import {useScrollStore} from '@local/stores'
import HeroImageWeb from '@local/assets/images/heroImageWeb.svg'
import HeroImageMobile from '@local/assets/images/heroImageMobile.png'
import Image from 'next/image'

export const Hero = ({heroCarouselTitle, bgColor}) => {
	const device = useDevice()
	const [lenis] = useScrollStore((state) => [state.lenis])

	const [selectedCarouselTitle, setSelectedCarouselTitle] = useState('')

	useEffect(() => {
		// Select a random carousel title when heroCarouselTitle changes
		if (heroCarouselTitle.length > 0) {
			const randomIndex = Math.floor(Math.random() * heroCarouselTitle.length)
			const selectedTitle = heroCarouselTitle[randomIndex]
			setSelectedCarouselTitle(selectedTitle)
		}
	}, [heroCarouselTitle])
	return (
		<section className={clsx('lg:bg-[#FCF9ED] bg-[#CAD3FB] md-max:h-580 relative z-0')}>
			<div className='relative pt-125 lg:pt-180 grid-container tooltip-container h-700 mb-100'>
				<span className='lg:text-center relative z-10 block translate-y-10 opacity-0 animate-hero-bg leading-narrow lg:mx-auto text-72 sm-max:text-44 sm-max:span-w-6 w-555 mb-5'>Stories of</span>
				<a
					data-tooltip-id='carousel-tooltip'
					data-tooltip-content={selectedCarouselTitle?.carouselDefinition}
					style={device === 'mobile' ? {color: '#8486Ec'} : {color: bgColor}}
					className={clsx(`lg:text-center underline relative z-10 block translate-y-10 opacity-0 animate-hero-bg leading-narrow lg:mx-auto text-72 sm-max:text-44 md-max:span-w-6 w-555 mb-86 lg:mb-100`)}>
					{selectedCarouselTitle?.carouselWord}
				</a>
				{device === 'mobile' ? <Tooltip id='carousel-tooltip' className='carousel-tooltip-sm w-300' place='bottom' /> : <Tooltip id='carousel-tooltip' style={{background: bgColor, zIndex: '10'}} className={clsx('carousel-tooltip-lg', `!bg-[${bgColor}]`)} place='bottom' />}

				<div onClick={() => lenis?.scrollTo?.('#featured-story')} className={clsx('md:hidden lg:flex md-max:hidden w-110 h-110 items-center justify-center rounded-full absolute right-0 bottom-0 translate-y-1/2', `bg-[${bgColor}]`)}>
					<svg fill='none' viewBox='0 0 27 27' className='w-28 h-28'>
						<path d='m10.691 17.773 7.322 7.32 7.321-7.32' stroke='#fff' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
						<path d='M1.905 1.666h10.25a5.857 5.857 0 0 1 5.857 5.857v17.571' stroke='#fff' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
					</svg>
				</div>
			</div>
			<Image src={HeroImageMobile} className='object-cover absolute bottom-0 lg:hidden h-250' alt='Hero Background Image for Mobile' />
			<HeroImageWeb className='absolute object-contain w-screen px-5 translate-y-10 opacity-0 h-535 top-100 md:hidden md-max:hidden lg:block animate-hero-bg' alt='Hero Background Image for Web' />
		</section>
	)
}
