import {clsx} from '@local/utils'
import Link from 'next/link'
import {ReactNode, useState} from 'react'
import {Controller, EffectCards} from 'swiper'
import 'swiper/css'
import 'swiper/css/effect-cards'
import {Swiper, SwiperSlide} from 'swiper/react'
import {SanityImage} from '../sanityImage/SanityImage'
export interface CarouselProps {
	children: ReactNode
	className: string
}

export function SlidePrevButton ({swiper, className}) {
	return (
		<div className={clsx('z-10 flex items-center content-center cursor-pointer sm-max:transform absolute top-0 left-0 -translate-y-1/2 sm-max:w-45 lg:w-82 lg:h-82 h-fit', className)} onClick={() => swiper.slidePrev()}>
			<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 82 82' fill='none'><circle cx='41' cy='41' r='41' fill='#FF9776' transform='rotate(-180 41 41)' /><path stroke='#fff' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M52 41H30M41 52 30 41l11-12' /></svg>
		</div>
	)
}

export function SlideNextButton ({swiper, className}) {
	return (
		<div className={clsx('z-10 flex content-center transform rotate-180 cursor-pointer absolute top-0 right-0 -translate-y-1/2 lg:items-center w-45 lg:w-82 h-fit sm-max:mb-auto', className)} onClick={() => swiper.slideNext()}>
			<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 82 82' fill='none'><circle cx='41' cy='41' r='41' fill='#FF9776' transform='rotate(-180 41 41)' /><path stroke='#fff' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M52 41H30M41 52 30 41l11-12' /></svg>
		</div>
	)
}
export const Carousel = ({carouselItems}: CarouselProps) => {
	const [firstSwiper, setFirstSwiper] = useState(null)
	const [secondSwiper, setSecondSwiper] = useState(null)
	const [currentIndex, setCurrentIndex] = useState(0)
	// console.log(firstSwiper)
	return (
		<div className='overflow-hidden relative'>
			<Swiper
				onActiveIndexChange={() => setCurrentIndex(firstSwiper.activeIndex)}
				effect='cards'
				grabCursor
				centeredSlides
				modules={[EffectCards, Controller]}
				onSwiper={setFirstSwiper}
				controller={{control: secondSwiper}}
				className='mySwiper lg:span-w-8 mt-65 lg:mt-120 '>
				{carouselItems?.map((carouselItem, idx) => {
					return (
						<SwiperSlide key={idx} className='flex items-center content-center bg-transparent select-none text-22'>
							<div className='flex flex-col'>
								<SanityImage className='object-cover md-max:margin-ml-1 md-max:span-w-6 span-w-8 h-170 lg:h-550' image={carouselItem._type === 'article' ? carouselItem.articleImage : carouselItem.resourceImage} />
							</div>
						</SwiperSlide>
					)
				})}

			</Swiper>
			<div className='relative flex items-center content-center mx-auto span-w-6 lg:span-w-10'>
				<SlidePrevButton className='lg:hidden' swiper={secondSwiper} />

				<Swiper
					autoHeight
					modules={[Controller]}
					className='flex items-center justify-center grid-container mt-30 lg:mt-93 '
					onSwiper={setSecondSwiper}
					controller={{control: firstSwiper}}>
					{
						carouselItems?.map((carouselItem, idx) => {
							return (
								<SwiperSlide key={idx} className='my-auto'>
									<div className='leading-tight text-center text-[#170600] text-26 lg:text-35 lg:w-430 mx-auto mb-18 lg:mb-21'>
										{carouselItem._type === 'article' ? carouselItem.articlePreviewTitle : carouselItem.resourcePreviewTitle}
									</div>
									<div className='mx-auto leading-tight text-center text-14 lg:text-18 lg:w-670'>
										{carouselItem._type === 'article' ? carouselItem.articlePreviewDescription : carouselItem.resourcePreviewTitle}
									</div>
								</SwiperSlide>
							)
						})
					}
				</Swiper>
				<SlideNextButton className='lg:hidden' swiper={secondSwiper} />

			</div>

			<Link href={`${carouselItems[currentIndex]._type === 'article' ? /article/ : /resource/}${carouselItems[currentIndex]?.slug.en.current}`} id='footer-cta' className='z-50 relative group flex items-center content-center text-16 rounded-full mb-50 lg:mb-67 py-15 mt-26 px-40 mx-auto w-fit h-fit text-center text-[#2e2e2e] bg-[#FF667D]'>
				<div id='footer-cta--inner' className='relative overflow-hidden text-white'>
					<div id='footer-cta--before' className='group-hover:translate-y-[-150%] transition-transform duration-500 ease-out-expo '>Learn More</div>
					<div id='footer-cta--after' className='absolute group-hover:translate-y-0 transition-transform duration-500 ease-out-expo translate-y-[150%] bottom-0'>Learn More</div>
				</div>
			</Link>
			<div className='flex relative items-center justify-center z-0'>
				<div className='bg-[#8283DA]/20 mx-auto h-655 absolute grid-container -translate-y-1/2 md-max:hidden z-0'>
					<SlidePrevButton className='md-max:hidden ml-32' swiper={secondSwiper} />
					<SlideNextButton className='md-max:hidden mr-32' swiper={secondSwiper} />
				</div>
			</div>
		</div>
	)
}
