import {pageRevalidate} from '@local/config'
import {getPageById, setLocale, excludeDraft} from 'lib/sanity'
import type {GetStaticProps, NextPage} from 'next'
import {Hero, FeaturedArticles, DirectorySlider, Carousel, ResourcesSlider} from '../../../../packages/ui/components'

const Home: NextPage = (props) => {
	const {featuredArticles, carouselArticles, sliderArticles, heroTitle, heroCarouselTitle, bgColor, resources} = props
	console.log(resources)
	return (
		<div>
			<Hero heroTitle={heroTitle} heroCarouselTitle={heroCarouselTitle} bgColor={bgColor} />
			<FeaturedArticles featuredArticles={featuredArticles} />
			<Carousel carouselItems={carouselArticles} />
			<DirectorySlider directoryItems={sliderArticles} />
			<ResourcesSlider directoryItems={resources} />
		</div>
	)
}

export const getStaticProps: GetStaticProps = async ({locale, defaultLocale, locales, preview = false}) => {
	setLocale(locale, defaultLocale, locales)

	const props = await getPageById('home', preview, false, /* groq */`
		featuredArticles[]{...reference->},
		carouselArticles[]{...reference->},
		sliderArticles[]{...reference->{slices[]{_type == 'personInfoNative' => {personName, media}}, slug}},
		heroTitle,
		heroCarouselTitle,
		'articles': *[_type == 'article' ${excludeDraft(preview)}] | order(date desc),
		'resources': *[_type == 'resource' ${excludeDraft(preview)}] | order(date desc)
	`)

	// console.log('PROPS', props)
	if (!props) return {notFound: true}
	const res = {
		props: {
			...props,
			preview,
		},
		revalidate: pageRevalidate,
	}
	return res
}

export default Home
