import {SanityImage} from '../sanityImage/SanityImage'
import {Slices} from '@local/ui/slices'
import Link from 'next/link'
import {AnimatedText} from '../animatedText/AnimatedText'
import {Button} from '../button/Button'

export interface FeaturedArticlesProps {
	featuredArticles: any
}

export const FeaturedArticles = ({featuredArticles}: FeaturedArticlesProps) => {
	return (
		<section id='featured-articles'>
			<section id='featured-story'>
				<div className='grid-container md-max:pt-50'>
					<Link href={`/article/${featuredArticles[0].slug.en.current}`}>
						<div className='flex flex-col lg:flex-row lg:mb-90 mb-76 group hover:cursor-pointer'>
							<div className='overflow-hidden bg-black span-w-6 lg:h-470 mr-41 md-max:mb-50'>
								<SanityImage priority className='object-cover transition-all span-w-6 lg:h-470 group-hover:transform group-hover:scale-105' image={featuredArticles[0].articleImage} />
							</div>
							<div className='flex flex-col span-w-6 lg:pl-32 lg:py-32'>
								<div className=''>
									<h2 className='mb-24 lg:mb-40 leading-snug lg:leading-tight text-30 lg:text-53'>
										<AnimatedText>
											{featuredArticles[0].title.en}
										</AnimatedText>
									</h2>
									<p className='text-14 lg:text-18 lg:w-420 lg:mb-40'>
										{featuredArticles[0].articlePreviewDescription}
									</p>
									<Button href={`/article/${featuredArticles[0].slug.en.current}`} className='group md:hidden w-fit lg:flex items-center content-center text-white cursor-pointer px-40 py-18 text-16 rounded-full md-max:hidden bg-[#FB9A74]' hover='rotate'>Learn More</Button>
								</div>
							</div>
						</div>
					</Link>

					<h3 className='text-26 lg:text-48 mb-22 lg:mb-49'>Other Stories</h3>
					<hr className='w-full mb-17 opacity-20 md-max:hidden' />
					<Slices slices={featuredArticles.slice(1)} />
				</div>
			</section>
		</section>
	)
}
