import {Swiper, SwiperSlide} from 'swiper/react'
import {SanityImage} from '../sanityImage/SanityImage'
import Link from 'next/link'
// Import Swiper styles
import 'swiper/css'

export interface DirectorySliderProps {
	directoryItems: any
}

export const DirectorySlider = ({directoryItems}: DirectorySliderProps) => {
	return (
		<div className=''>
			<h3 className='mt-40 margin-ml-1 text-26 lg:mt-95 lg:text-48 mb-31 lg:mb-49'>Directory</h3>

			<Swiper
				spaceBetween={24}
				centerInsufficientSlides
				className='!margin-pl-1'
				grabCursor
				slidesPerView='auto'>
				{
					directoryItems.map((item, idx) => {
						return (
							<SwiperSlide key={idx} className='!w-270 lg:!w-420 select-none'>
								<SanityImage image={item.slices[0].media} className='object-cover h-280 lg:min-h-430 lg:w-420' desktopWidth={420} />
								<div className='mt-16 mb-12 lg:mt-24 lg:mb-16 w-215 lg:w-290'>
									{item.slices[0].personName}
								</div>
								<Link className='underline' href={`/article/${item.slug.en.current}`}>Learn More</Link>

							</SwiperSlide>
						)
					})
				}
				<SwiperSlide slot='wrapper-end' className='!w-270 lg:!w-420 align-top !h-280 lg:!h-430 bg-[#8283DA] !flex items-center margin-mr-1'>
					<Link href='/people' className='mx-auto mt-16 mb-12 text-center text-white lg:mt-24 lg:mb-16 text-36 lg:text-72'>
						All People
					</Link>
				</SwiperSlide>
			</Swiper>
		</div>
	)
}
