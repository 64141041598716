import dynamic, {DynamicOptions} from 'next/dynamic'
import {Fragment} from 'react'

const components = {
	resourceSection: dynamic(import('./resourceSection/ResourceSection').then(m => m.ResourceSection) as DynamicOptions<ResourceSectionProps>),
	pictureGallery: dynamic(import('./pictureGallery/PictureGallery').then(m => m.PictureGallery) as DynamicOptions<PictureGalleryProps>),
	galleryLayout1: dynamic(import('./galleryLayout1/GalleryLayout1').then(m => m.GalleryLayout1) as DynamicOptions<GalleryLayout1Props>),
	spotifyPodcast: dynamic(import('./spotifyPodcast/SpotifyPodcast').then(m => m.SpotifyPodcast) as DynamicOptions<SpotifyPodcastProps>),
	companyInfoCard: dynamic(import('./companyInfoCard/CompanyInfoCard').then(m => m.CompanyInfoCard) as DynamicOptions<CompanyInfoCardProps>),
	personInfoNative: dynamic(import('./person/Person').then(m => m.Person) as DynamicOptions<PersonProps>),
	article: dynamic(import('./featuredArticleCard/FeaturedArticleCard').then(m => m.FeaturedArticleCard) as DynamicOptions<FeaturedArticleCardProps>),
	articleVideo: dynamic(import('./articleVideo/ArticleVideo').then(m => m.ArticleVideo) as DynamicOptions<ArticleVideoProps>),
	fullWidthCircle: dynamic(import('./fullWidthCircle/FullWidthCircle').then(m => m.FullWidthCircle) as DynamicOptions<FullWidthCircleProps>),
	fullWidthRectangle: dynamic(import('./fullWidthRectangle/FullWidthRectangle').then(m => m.FullWidthRectangle) as DynamicOptions<FullWidthRectangleProps>),
	halfWidthDiffSide: dynamic(import('./halfWidthDiffSide/HalfWidthDiffSide').then(m => m.HalfWidthDiffSide) as DynamicOptions<HalfWidthDiffSideProps>),
	fullWidthMedia: dynamic(import('./fullWidthMedia/FullWidthMedia').then(m => m.FullWidthMedia) as DynamicOptions<FullWidthMediaProps>),
	halfWidthMedia: dynamic(import('./halfWidthMedia/HalfWidthMedia').then(m => m.HalfWidthMedia) as DynamicOptions<HalfWidthMediaProps>),
	halfWidthSameSide: dynamic(import('./halfWidthSameSide/HalfWidthSameSide').then(m => m.HalfWidthSameSide) as DynamicOptions<HalfWidthSameSideProps>),
	timeline: dynamic(import('./timeline/Timeline').then(m => m.Timeline) as DynamicOptions<TimelineProps>),
	unimplemented: dynamic(import('./UnimplementedSlice').then(m => m.UnimplementedSlice) as DynamicOptions<UnimplementedSliceProp>),
}

const Slices = ({slices, slicesProps = {}, sliceProps = {}}) => {
	const _slices = process.env.NODE_ENV === 'development' ? slices : slices?.filter(slice => Boolean(slice) && slice._type in components)
	if (_slices) {
		return _slices.map((slice, i) => {
			const Component = components[slice._type]
			const extraProps = sliceProps[slice._type] || []

			if (Component) {
				return (
					<Component key={slice._key || i} index={i} {...slice} {...slicesProps} {...extraProps} />
				)
			} else {
				if (process.env.NODE_ENV === 'development') {
					const UnimplementedSlice = components.unimplemented
					return (
						<UnimplementedSlice key={slice._key || i} index={i} {...slice} {...slicesProps} />
					)
				} else {
					return <Fragment key={slice._key || i} />
				}
			}
		})
	} else return null
}

export default Slices
